$color: #6AB97A;
$border-color: #C4C4C4;

.checkbox {
  font-size: 2rem;
  width: 1.563rem;
  height: 1.563rem;
  overflow: hidden;
  border-radius: 0.15em;
  color: $color;
  cursor: pointer;

  &--disabled {
    color: var(--disabled);
  }
}

.checkbox__control {
  width: 1.563rem;
  height: 1.563rem;
  border-radius: 0.15em;
  border: 0.125rem solid $border-color;
  padding: 1px;

  svg {
    transition: transform 0.1s ease-in 25ms;
    transform: scale(0);
    transform-origin: bottom left;
  }

  &_checked {
    border: 0.125rem solid #FFFFFF;
  }
}

.checkbox__input {
  display: flex;
  border-radius: 0.25em;

  > * {
    grid-area: checkbox;
  }

  input {
    opacity: 0;
    cursor: pointer;
    position: absolute;

    &:checked + .checkbox__control svg {
      transform: scale(1);
    }

    &:disabled + .checkbox__control {
      color: var(--disabled);
    }
  }

  &_checked {
    background-color: #FFFFFF;
  }
}
