$border-radius: 0.5rem;

.dropdown {
  box-shadow: 0 0.5rem 1.75rem -0.375rem rgba(11, 17, 33, 0.12), 0 1.125rem 5.5rem -0.25rem rgba(11, 17, 33, 0.04);
  border-radius: $border-radius;
  left: 0;
  top: 100%;
  background: #FFFFFF;
}

.input {
  @apply
  placeholder-chinese-silver
  text-dark-charcoal
  bg-white
  w-full
  h-14
  pr-6
  py-4
  rounded-lg
  border
  border-bright-gray
  transition
  duration-30
  outline-none
  pl-4
  text-base
  ease-in-out;

  &:hover {
    @apply border-davy-grey;
  }

  &:focus {
    @apply border-iguana-green caret-iguana-green;
  }
}

.disabled {
  pointer-events: none;
  @apply
  bg-transparent
  text-opacity-50;
}
