@keyframes anvil {
  0% {
    transform: scale(1) translateY(0);
    opacity: 0;
  }
  1% {
    transform: scale(0.96) translateY(0.625rem);
    opacity: 0;
  }
  100% {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
}

.custom-popup-content {
  width: 16.5rem;
  background: #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0 0.5rem 1.75rem -0.375rem rgba(11, 17, 33, 0.12), 0 1.125rem 5.5rem -0.25rem rgba(11, 17, 33, 0.14);
  border: none;
  border-radius: 0.375rem;
  overflow: hidden;
  padding: 0.5rem 0.5rem;

  animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}
