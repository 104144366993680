@tailwind base;
@tailwind components;
@tailwind utilities;
@import "fonts";

body {
  @apply font-roboto-regular h-screen w-screen;
  #root {
    @apply h-full w-full;
  }
}

/* custom scrollbar */
*::-webkit-scrollbar {
  width: 0.375rem;
  height: 0.563rem;
}
*::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 0.313rem;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}
*::-webkit-scrollbar-track {
  // background: #eeeeee;
}

.popupDotsTrigger {
  & > svg > path {
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    & > svg > path {
      fill: #333333;
    }
  }
}
