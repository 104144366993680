.btn {
  @apply
  flex
  py-2
  px-3
  cursor-pointer
  rounded-md
  w-full
  text-davy-grey
  hover:bg-cultured
  transition-colors
  duration-200
  items-center;
}
