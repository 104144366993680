.digitInput {
  @apply
  placeholder-chinese-silver
  text-dark-charcoal
  bg-white
  w-14
  h-14
  flex
  text-center
  text-2xl

  rounded-lg
  border
  border-bright-gray
  transition
  duration-30
  outline-none
  font-roboto-regular
  ease-in-out;

  &:hover {
    @apply border-davy-grey;
  }

  &:focus {
    @apply border-iguana-green caret-iguana-green;
  }

  &:focus::placeholder {
    color: transparent;
  }

  &:disabled {
    pointer-events: none;
    @apply bg-transparent
    text-opacity-50;
  }
}
