@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scaleY(0);
  }

  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}

.menu {
  transform-origin: top center;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: scaleY(1);
  }

  100% {
    opacity: 0;
    transform: scaleY(0);
  }
}

.menu__close {
  transform-origin: top center;
  animation: fadeOut 0.3s ease-in-out;
}
