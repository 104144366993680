.with-shadow {
  box-shadow: 0 0.5rem 1.75rem -0.375rem rgba(11, 17, 33, 0.12), 0 1.125rem 5.5rem -0.25rem rgba(11, 17, 33, 0.14);
}

.disabledChange {
  :global {
    .react-calendar__viewContainer {
      pointer-events: none;
    }
  }
}

.custom-calendar {
  border: none;
  border-radius: 0.625rem;
  padding: 1rem 1rem;
  width: 21.375rem;

  :global {
    .react-calendar__tile {
      font-size: 0.938rem;
      line-height: 0.938rem;
      color: #333333;

      flex-basis: auto !important;

      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;

      transition: .3s ease;

      &.react-calendar__tile--active {
        background: #6AB97A;
        color: #FFFFFF;
      }

      &:enabled:hover, &:enabled:focus {
        background: #6AB97A;
      }

      &:hover {
        background: lighten($color: #6AB97A, $amount: 0.8);
        color: #FFFFFF;
      }
    }

    .react-calendar__tile:disabled {
      color:#959595;
    }

    .react-calendar__navigation__arrow {
      display: flex;
      align-items: center;
      width: 2.5rem;
      padding: 0 0.5rem;

      &.react-calendar__navigation__next-button {
        justify-content: flex-start;
      }

      &.react-calendar__navigation__next-button {
        justify-content: flex-end;
      }
    }

    .react-calendar__month-view__weekdays {
      display: none !important;
    }

    .react-calendar__navigation {
      display: none;
    }

    .react-calendar__month-view__days {
      gap: 0.313rem;
    }

    .react-calendar__month-view__days__day--neighboringMonth > abbr {
      color: #959595;
      opacity: 0.9;
    }
  }
}
